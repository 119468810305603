/* eslint-disable no-restricted-syntax */
import React, { useEffect } from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import axios from 'axios';
import { Grid, Fab, Tooltip, Typography } from '@material-ui/core';
import LinkMaterial from '@material-ui/core/Link';
import Container from '@material-ui/core/Container';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import TelegramIcon from '@material-ui/icons/Telegram';
import HomeCard from '../components/HomeCard';
import HomeBanner from '../static/images/home_full_web.png';
import HomeBannerMobile from '../static/images/home_small.png';
import Utils from '../shared/Utils';
import Service from '../shared/Services';

const useStyles = makeStyles((theme) => ({
  outer: {
    height: '60vh',
  },
  imageTop: {
    backgroundImage: `url(${HomeBannerMobile})`,
    [theme.breakpoints.down(600)]: {
      borderRadius: '16px',
    },
    [theme.breakpoints.up(600)]: {
      backgroundImage: `url(${HomeBanner})`,
    },
    [theme.breakpoints.up(1200)]: {
      backgroundImage: `url(${HomeBanner})`,
    },
    [theme.breakpoints.up(1600)]: {
      backgroundImage: `url(${HomeBanner})`,
    },
    backgroundRepeat: 'no-repeat',
    backgroundColor:
      theme.palette.type === 'light'
        ? theme.palette.grey[50]
        : theme.palette.grey[900],
    backgroundSize: 'cover',
    backgroundPosition: 'inherit',
    height: '20vh',
    [theme.breakpoints.up(1200)]: {
      height: '30vh',
    },
  },
  cardGrid: {
    paddingTop: theme.spacing(3),
  },
  telegram: {
    position: 'fixed',
    bottom: '3rem',
    right: '2rem',
    zIndex: 5,
    background: '#263a4a',
    color: '#fff',
    '&:hover': {
      background: '#000',
    },
  },
}));

const CustomTooltip = withStyles({
  tooltip: {
    color: 'lightblue',
    backgroundColor: 'green',
  },
})(Tooltip);

function HomePage(props) {
  const classes = useStyles();
  const matches = useMediaQuery('(min-width:600px)');
  let acadamy = require('../static/images/homeAcademy12.png');
  // let upskill = require('../static/images/homeUpskill12.png');
  let store = require('../static/images/homeStoreMobile.png');

  if (matches) {
    acadamy = require('../static/images/homeAcademyWeb.png');
    // upskill = require('../static/images/homeUpskillWeb.png');
    store = require('../static/images/homeStoreWeb.png');
  }
  const [link, setLink] = React.useState([]);
  const [values, setValues] = React.useState({
    packages: [],
    to: '#',
    isLoading: true,
  });

  useEffect(() => {
    Service.getTelegramLink().then((res) => {
      setLink(res.data.link);
    });
  }, []);

  useEffect(() => {
    // code to run on component mount
    if (values.to === '#') {
      axios
        .all([Service.getUserDetails(), Service.getPackageDetails()])
        .then(
          axios.spread((user, pkgs) => {
            let to = '/player/register';
            if (user.data.is_coach) {
              to = '/coach';
              props.dispatch({ type: 'COACH' });
            } else if (Array.isArray(pkgs.data)) {
              if (Utils.hasActivePackages(pkgs.data)) {
                to = '/player/active';
                props.dispatch({ type: 'PAID' });
              } else if (Utils.hasNewInActivePackages(pkgs.data)) {
                to = '/player/notpaid';
                props.dispatch({ type: 'NOTPAID' });
              }
            }
            setValues((oldValues) => ({
              ...oldValues,
              packages: pkgs.data,
              to,
              isLoading: false,
            }));
          })
        )
        .catch((error) => {
          setValues((oldValues) => ({
            ...oldValues,
            to: '/player/register',
            isLoading: false,
          }));
        });
    }
  });

  const longText = (
    <>
      <Typography variant="subtitle1" style={{ fontSize: '12px' }}>
        Hi User,
        <span role="img" aria-label="hi">
          👋
        </span>
        We are happy to connect with you through Telegram. Subscribe for more!
      </Typography>
      <br />
      <Typography variant="subtitle1" style={{ fontSize: '10px' }}>
        <span role="img" aria-label="Academy" style={{ fontSize: '18px' }}>
          🏏
        </span>
        Get trained with expert coaches at our academies along with
        certifications and camps <br />
        <span role="img" aria-label="AI" style={{ fontSize: '15px' }}>
          🤖
        </span>
        Check out our video uploading tool powered by AI <br />
        <span role="img" aria-label="Store" style={{ fontSize: '15px' }}>
          🛍️
        </span>
        Shop from certified products at SK stores and much more
      </Typography>
    </>
  );

  return (
    <Grid className={classes.outer} container component="main">
      <Grid item xs={12} sm={12} md={12} className={classes.imageTop} />
      <Container className={classes.cardGrid} maxWidth="md">
        <Grid container spacing={4} justifyContent="space-between">
          {values.to === '/coach' && (
            <HomeCard
              to={values.to}
              text="Academy"
              image={acadamy}
              isLoading={values.isLoading}
            />
          )}
          {values.to !== '/coach' && (
            <HomeCard
              href="https://app.skonnect.io/"
              text="Academy"
              image={acadamy}
              isLoading={values.isLoading}
            />
          )}

          <HomeCard
            href="https://sportskingdomstores.io/"
            text="Stores"
            image={store}
          />
          {/* <HomeCard to="/upskill" text="Up Skill" image={upskill} /> */}
        </Grid>
      </Container>
      <CustomTooltip title={longText}>
        <Fab
          aria-label="add"
          className={classes.telegram}
          component={LinkMaterial}
          href={link}
          target="_blank"
          rel="noopener"
        >
          <TelegramIcon />
        </Fab>
      </CustomTooltip>
    </Grid>
  );
}

export default connect()(HomePage);
