import React, { useEffect } from 'react';
import { Route, Switch, Link, Redirect } from 'react-router-dom';
import { makeStyles, createMuiTheme } from '@material-ui/core/styles';
import BottomNavigation from '@material-ui/core/BottomNavigation';
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction';
import RestoreIcon from '@material-ui/icons/Restore';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import Person from '@material-ui/icons/Person';
import { connect } from 'react-redux';
import ReactGA from 'react-ga4';
// eslint-disable-next-line import/no-extraneous-dependencies
import { ThemeProvider } from '@material-ui/styles';
import SignIn from './page_components/SignIn';
import SignUp from './page_components/SignUp';
import Profile from './page_components/Profile';
import HomePage from './page_components/HomePage';
// import CoachCeleb from './page_components/booking/page2/CoachCeleb';
import CoachHome from './page_components/coach/CoachHome';
// import CoachCelebProfile from './page_components/CoachCelebProfile';
import Service from './shared/Services';
import Utils from './shared/Utils';
import './App.css';
import PersistentDrawerLeft from './components/PersistentDrawerLeft';
import Montserrat from './static/fonts/montserrat-v14-latin-regular.woff2';
// import PartnerSignUp from './page_components/partner/PartnerSignUp';
// import PartnerHome from './page_components/partner/PartnerHome';
// import Partner from './page_components/partner/Partner';

const montserrat = {
  fontFamily: 'Montserrat',
  fontStyle: 'normal',
  fontDisplay: 'swap',
  fontWeight: 400,
  src: `
    local('Montserrat'),
    local('Montserrat-Regular'),
    url(${Montserrat}) format('woff2')
  `,
  unicodeRange:
    'U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF',
};

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#474d6c', // #dusk
      light: '#eef2fb', // #pale_grey
      dark: '#292929', // #black
      contrastText: '#fff',
      facebook: '#14548f', // ##light_navy
    },
    secondary: {
      // This is green.A700 as hex.
      main: '#ed1c24', //  #pinkish_red
      light: '#e1e1e1', // #white_two
      contrastText: '#fff',
      dark: '#292929', // #black
      facebook: '#14548f', // ##light_navy
    },
  },
  typography: {
    fontFamily: 'Montserrat',
  },
  overrides: {
    MuiCssBaseline: {
      '@global': {
        '@font-face': [montserrat],
      },
    },
    MuiStepIcon: {
      root: {
        color: '#ed1c24',
      },
    },
    MuiLink: {
      root: {
        color: '#ed1c24',
      },
    },
  },
});
const useStyles = makeStyles((theme) => ({
  footer: {
    width: 'auto',
    height: '10%',
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(2) * 2)]: {
      width: 600,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
}));

// initialize Google Analytics on the root
ReactGA.initialize('G-L2Q4MTG12G');
// createBrowserHistory(window);
// ReactGA.pageview(window.location.pathname + window.location.search);

function App(props) {
  const classes = useStyles();
  const [value, setValue] = React.useState('register');

  useEffect(() => {
    // code to run on component mount
    if (localStorage.getItem('JWT_TOKEN')) {
      Utils.updateJWTFromSession();
      if (!props.isLoggedUser) {
        Service.getUserDetails()
          .then((res) => {
            if (!props.isLoggedUser) {
              props.dispatch({ type: 'SIGNED_IN' });
            }
          })
          .catch((error) => {
            if (props.isLoggedUser) {
              props.dispatch({ type: 'SIGNED_OUT' });
            }
          });
      }
    } else if (props.isLoggedUser) {
      props.dispatch({ type: 'SIGNED_OUT' });
    }
  }, [props]);

  function handleChange(event, newValue) {
    if (newValue === 'store') {
      // TO DO Store

      setValue(newValue);
    } else {
      setValue(newValue);
    }
  }

  const publicPages = [
    '/',
    '/signin',
    '/signup',
    '/mentor',
    '/training',
    '/upskill',
    '/training/details',
    '/mentor/profile',
    '/partner/signup',
    '/partner',
    '/partner/register',
    '/register/coach',
    '/register/celeb',
    '/player/register',
  ];

  // eslint-disable-next-line consistent-return
  const renderRedirectLanding = () => {
    if (!props.isLoggedUser && !publicPages.includes(props.location.pathname)) {
      return <Redirect to="/" />;
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <div className="App">
        <PersistentDrawerLeft />
        <Switch>
          <Route exact path="/register" component={HomePage} />
          <Route exact path="/" component={HomePage} />
          <Route exact path="/signup" component={SignUp} />
          <Route exact path="/profile" component={Profile} />
          <Route exact path="/mybookings" component={HomePage} />
          <Route exact path="/signin" component={SignIn} />
          <Route exact path="/packages" component={HomePage} />
          <Route exact path="/player/notpaid" component={HomePage} />
          <Route exact path="/player/register" component={HomePage} />
          <Route exact path="/player/active" component={HomePage} />
          <Route exact path="/coach" component={CoachHome} />
          <Route exact path="/training" component={HomePage} />
          <Route exact path="/upskill" component={HomePage} />
          <Route path="/training/details" component={HomePage} />
          {/* <Route exact path="/mentor" component={CoachCeleb} />
          <Route path="/mentor/profile" component={CoachCelebProfile} />
          <Route path="/partner/signup" component={PartnerSignUp} />
          <Route path="/partner/home" component={PartnerHome} />
          <Route path="/partner/" component={Partner} /> */}
        </Switch>
        {/* If User not Loggin */}
        {false && (
          <BottomNavigation
            value={value}
            onChange={handleChange}
            showLabels
            className={classes.footer}
          >
            <BottomNavigationAction
              value="register"
              component={Link}
              to="/register"
              label="Academy"
              icon={<RestoreIcon />}
            />
            <BottomNavigationAction
              value="store"
              label="Store"
              icon={<LocationOnIcon />}
            />
            <BottomNavigationAction
              value="signin"
              component={Link}
              to="/"
              label="SignIn"
              icon={<Person />}
            />
          </BottomNavigation>
        )}
        {renderRedirectLanding()}
      </div>
    </ThemeProvider>
  );
}

function mapStateToProps(state) {
  return {
    isLoggedUser: state.isLoggedUser,
  };
}

export default connect(mapStateToProps)(App);
